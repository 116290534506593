import { createI18n } from 'vue-i18n';
import messages from '@intlify/unplugin-vue-i18n/messages';
import {
	format,
	formatDistanceToNow,
	formatDistanceToNowStrict,
	Locale,
} from 'date-fns';
import { nl, enGB } from 'date-fns/locale';
import { ComputedRef, WritableComputedRef } from 'vue';

const systemLanguage = navigator.language.startsWith('nl') ? 'nl' : 'en';
const savedLocale = useLocalStorage<'nl' | 'en'>(
	'plugin-i18n/savedLocale',
	systemLanguage,
);

export const i18n = createI18n({
	legacy: false,
	locale: savedLocale.value,
	messages,
});

import { configure } from 'vee-validate';
import { localize, setLocale as vvLocale } from '@vee-validate/i18n';
import vvEn from '@vee-validate/i18n/dist/locale/en.json';
import vvNl from '@vee-validate/i18n/dist/locale/nl.json';

configure({
	generateMessage: localize({
		en: vvEn,
		nl: vvNl,
	}),
});

vvLocale('nl');

export function setLocale(lang?: 'nl' | 'en') {
	let l = lang;
	if (!l) l = savedLocale.value;
	else savedLocale.value = l;

	const loc = i18n.global.availableLocales.find((loc) => {
		return loc.toString() === lang;
	});
	if (!loc) return;
	i18n.global.locale.value = loc;
	vvLocale(l);
}
export function getLocale(mutateable?: false): ComputedRef<'nl' | 'en'>;
export function getLocale(mutateable: true): WritableComputedRef<'nl' | 'en'>;
export function getLocale(mutateable = false) {
	if (mutateable)
		return computed({
			get: () => savedLocale.value,
			set: (v) => {
				setLocale(v);
			},
		});
	return computed(() => savedLocale.value);
}

export function localFormat(date: Date, f: string) {
	let locale: Locale;
	if (savedLocale.value == 'nl') locale = nl;
	else locale = enGB;
	return format(date, f, { locale });
}
export function localFormatDistanceToNow(date: Date) {
	let locale: Locale;
	if (savedLocale.value == 'nl') locale = nl;
	else locale = enGB;
	return formatDistanceToNow(date, { locale });
}
export function localFormatDistanceToNowStrict(date: Date) {
	let locale: Locale;
	if (savedLocale.value == 'nl') locale = nl;
	else locale = enGB;
	return formatDistanceToNowStrict(date, { locale, addSuffix: true });
}
