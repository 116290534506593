import {
	apollo,
	clientsRefDefault,
	settings,
} from '@compenda/vue/plugin-apollo';
import { i18n, setLocale } from '@compenda/vue/plugin-i18n';
import { pinia } from '@compenda/vue/plugin-pinia';
import App from './App.vue';
import router from './router';
import '@angelblanco/v-calendar/style.css';
import './main.css';
// eslint-disable-next-line @nx/enforce-module-boundaries
import '../../../libs/assets/fontawesome/assets/fontawesome/css/all.css';

import {
	useRoamingSetting,
	registerItemChanged,
} from '@compenda/vue/data-outlook';

Office.onReady(({ host }) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore this is a weird vue-tsc issue
	if (import.meta.env.PROD && host !== Office.HostType.Outlook) {
		return;
	}

	const app = createApp(App);

	settings.clientsRef = useRoamingSetting(
		'connectionClientsRef',
		clientsRefDefault,
	);
	settings.currentClientNameRef = useRoamingSetting(
		'connectionCurrentClientNameRef',
		'default',
	);
	registerItemChanged();

	app.use(pinia);
	app.use(router);
	app.use(apollo);
	app.use(i18n);

	// use OfficeLang
	const locale =
		Office.context.displayLanguage.split('-')[0] === 'nl' ? 'nl' : 'en';
	setLocale(locale);

	app.mount('#app');
});
